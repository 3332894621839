@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
:root {
  --primary: #ffffff;
  --secondary: #eff1f5;
  --accent: #21cd9c;
  --fontColor: #2d395a;
  --themeText: #ffffff;
  --hoverColor: #085942 ;
  --fontFamily: "Montserrat", sans-serif;
  --border: 1px solid #dfdfdf;
}
body,
html {
  scroll-behavior: smooth;
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  background-color: var(--secondary);
}
h4,
.modal-title {
  font-weight: 700;
  margin: 0 !important;
}
h5,
h6 {
  font-weight: 600;
}
h6 {
  font-size: 1rem !important;
}
a {
  cursor: pointer !important;
}
.uuki-community-btn {
  background-color: var(--accent) !important;
  color: var(--themeText) !important;
}
.uuki-community-btn:hover {
  background-color: var(--hoverColor) !important;
}
.home-link > .active {
  background-color: var(--accent) !important;
  color: var(--themeText) !important ;
}
.nav-uuki {
  height: 4rem;
}
.community-pages-header {
  font-size: 18px;
  font-weight: 700;
}
.uuki-btn {
  font-weight: 600 !important;
  /*border-radius: 1.8rem !important;*/
  padding: 0.5rem 1rem !important;
  /* background-color: var(--accent) !important; */
  /* font-size: 1.125rem !important; */
  border: 0 !important;
  /* color:var(--themeText) !important; */
  min-width: 8rem;
}
.uuki-logo {
  width: 3.25rem;
  height: 3.25rem;
}

.link-card {
  text-decoration: none !important;
  color: #2d395a !important;
}

.link-text {
  color: var(--fontColor) !important;
}

.overflow-text {
  word-break: break-all;
}

.member-image {
  width: 2rem;
  height: 2rem;
}
.content-border-bottom {
  border-bottom: 1px solid #d6dbe6;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}
.content-border {
  border-top: 1px solid #d6dbe6;
}

/* .tab-content.dashboard-content,.right-nav-block,.recent-activity-bar,.post-item-bg,.create-post-bg{ */
.uuki-box {
  border-radius: 0.25rem;
  border: var(--border);
  /* box-shadow: 0 1px 0 0 rgba(40, 42, 45, 0.12); */
  /* background-color: var(--primary); */
}
.navbar-toggler {
  border: 0 !important;
}

.hidden-class {
  display: none !important;	
}

.offcanvas-start {
  max-width: 20rem !important;
}

.btn:focus,
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.post-toggle {
  background-color: transparent !important;
  color: inherit !important;
  border: 0 !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.dropdown-menu {
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem !important;
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.33);
}
.modal-content {
  background-color: var(--primary) !important;	
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem !important;
  background-color: var(--primary) !important;
}


.modal-header > .btn-close {
  position: absolute;
  right: 0.4rem;
  top: -1.5rem;
  background-image: url("../assets/close-btn.svg") !important;
}

.modal-backdrop.show {
  opacity: 0.8 !important;
}

.form-check-input:checked {
  background-color: var(--accent) !important;	
}

.popover-arrow {
  display: none !important;
}

.navbar-popover {
  border-radius: 0.8rem !important;
  min-width: 12rem;
  max-width: 24rem;
  font-weight: 600 !important;
  box-shadow: 0 0 0.8rem 0 rgba(0, 0, 0, 0.33);
}

.navbar-popover-header {
  border-radius: 0.8rem 0.8rem 0 0 !important;
  padding: 1rem 1.6rem !important;
  background-color: transparent !important;
}

.pointer-cursor {
  cursor: pointer !important;
}

.wrapword {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    white-space: pre-wrap;       /* css-3 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    white-space: normal;
}

.nav-item > .accordion > .accordion-item{
  background-color: transparent !important;
}

.scroll-nav::-webkit-scrollbar {
  display: none;
}

.scroll-nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ce-popover.ce-popover--opened {
  margin-bottom: 4rem !important;
}
.loader-spinner {
  width: 36px;
  height: 36px;
  border: 7px solid #fff;
  border-bottom-color: var(--accent);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-spinner-main {
  width: 48px;
  height: 48px;
  border: 7px solid #fff;
  border-bottom-color: var(--accent);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .uuki-logo {
    max-width: 3rem;
  }
  .nav-uuki {
    height: 3.6rem;
  }
  .uuki-btn {
    padding: 0.5rem 0.7rem !important;
  }
  body,
  html {
    font-size: 14px !important;
  }
}

/* * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  } */
