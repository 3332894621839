.tabs-container {
  margin-top: 100px !important;
  background-color: transparent !important;
}

.container-delete-approve {
  width: 100% !important;
}

.ban-word-text {
  margin-top: 56px;
  margin-left: 36px;
  margin-bottom: 56px;
  margin-right: 72px;
}

.ban-word-container {
  box-sizing: border-box;
  width: 198px;
  height: 125px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  color: #2d395a;
}

.banword-form-block {
  box-sizing: border-box;

  height: 40px;
  background: #ffffff;
  border: 0.2px solid #dfdfdf;
  border-radius: 4px;
}

.white-bg-container {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}
