body {
  /* font-family: "MetropolisMedium" !important ; */
  color: #2d395a;
}

.action-btn {
  width: 20% !important;
}

.signup-btn {
  background-color: transparent !important;
  font-family: "MetropolisBold" !important;
  border: 0px !important;
  color: #2d395a !important;
  padding: 0px !important;
}

.signup-btn:hover {
  background-color: transparent !important;
  font-family: "MetropolisBold" !important;
  border: 0px !important;
  color: #2d395a !important;
  padding: 0px !important;
  text-decoration: underline !important;
}
