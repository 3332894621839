.cascade-image {
  margin-left: -0.3rem;
  height: 1.375rem;
  width: 1.375rem;
}
.main-row,
#offcanvasNavbar {
  background-color: var(--secondary);
  /* overflow-x: hidden; */
}
.navbar-toggler {
  box-shadow: none !important;
}
.notif-list {
  background-color: var(--secondary) !important;
  color: var(--fontColor) !important;
}
.fa-ellipsis{
  color: var(--fontColor) !important;
}
.navbar-notif-list {
  max-height: 25rem !important;
  overflow-y: scroll;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.navbar-notif-list::-webkit-scrollbar {
  display: none;
}

.loader-background {
  background-color: #dcdcdc;
  width: 20rem;
  height: 0.2rem;
  overflow-x: hidden;
}

#search-bar {
  background-color: #e9ecef;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  max-width: 100% !important;
  border-color: #DFDFDF !important;
}
.loader {
  background-color: #21cd9c;
  width: 5rem;
  height: 0.2rem;
  animation: loader-bounce 2s ease infinite;
}
.desktop-profile-image {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}
@keyframes loader-bounce {
  0% {
    left: -12rem;
  }
  5% {
    left: -10rem;
  }
  10% {
    left: -8rem;
  }
  15% {
    left: -6rem;
  }
  20% {
    left: -4rem;
  }
  25% {
    left: -2rem;
  }
  30% {
    left: 0rem;
  }
  35% {
    left: 2rem;
  }
  40% {
    left: 4rem;
  }
  45% {
    left: 6rem;
  }
  50% {
    left: 8rem;
  }
  55% {
    left: 10rem;
  }
  60% {
    left: 12rem;
  }
  65% {
    left: 14rem;
  }
  70% {
    left: 16rem;
  }
  75% {
    left: 18rem;
  }
  80% {
    left: 20rem;
  }
  85% {
    left: 22rem;
  }
  90% {
    left: 24rem;
  }
  95% {
    left: 26rem;
  }
  100% {
    left: 28rem;
  }
}

.recent-activity-bar,
.post,
.join-block,
.course-block,
.right-nav-block,
.view-leaderboard-container,
.edit-wrapper,
.member-box {
  background-color: var(--primary);
  color: var(--fontColor);
}
.post-cover-image-grid {
  height: auto;	
}
.like-badge {
  right: -0.4rem;
  top: -0.5rem;
  font-size: 10px;
  height: 1rem;
  width: 1rem;
  background-color: var(--accent);
  /* padding: 0.1rem 0.6rem; */
  color: var(--primary);
}
.community-name {
  color: var(--accent) !important;
  max-width: 15rem !important;
}
.leaderboard-rank {
  background: var(--accent) !important;
  border-radius: 0.25rem;
  /* border: solid 1px var(--accent); */
  font-size: 12px;
  color: var(--themeText);
  padding: 0.3rem 1rem 0.25rem 1rem;
  font-weight: 500 !important;
  opacity: 0.8;
  align-self: flex-start;
  /* font-size: 1rem; */
}
#postTitle{
  border: 0;
  background-color: var(--secondary);
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 1rem;
  height: 5rem !important;
}
#postTitle::placeholder{
 font-weight: 500;
 font-size: 1.2rem;
}
#postTitle:focus::placeholder{
  color: transparent;
 }

.trending-post-image {
  height: 1.75rem;
  width: 1.75rem;
}
.mobile-profile-image {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}

.post-footer-divider {
  height: 1px !important;
  border: solid 1px #d6dbe6 !important;
}
.post {
  color: var(--fontColor);
  width: 100%;
}
.post-title {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}

.post-item-poll-options-progress {
  width: 100%;
  height: 2rem !important;
}
.option-text{
  position:absolute;
  top:0.5rem;
  left:2.8rem;
  max-width: 25rem;
}
.post-item-poll-options-progress>.progress-bar{
  background-color: lightblue !important;
}
.poll-option{
  min-height: 2.5rem;
}
.poll-option-radio>.form-check-input{
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.1rem;
  cursor: pointer;
}
.right-nav-block>.progress>.progress-bar {
  background-color: var(--accent) !important;		
}
.right-link {
  background-color: transparent !important;
}
.post-author-image {
  width: 3rem;
  height: 3rem;
}
.post-author {
  font-weight: 600;
}
.brand-logo {
  width: auto;
  height: 2.5rem !important;
  object-fit: contain;
}
.post-author-image-list {
  width: 2.65rem;
  height: 2.65rem;
}
.codex-editor {
  background-color: var(--secondary);
  padding: 1rem;
  border-radius: 0.5rem;
}
.post-edit>div:nth-child(2)>.codex-editor{
  border-radius: 0 0 0.5rem 0.5rem;
}
.ce-paragraph::before{
  font-size:1.2rem;
}
.ce-toolbar__content {
  margin-right: 1rem;
}
.home-link > .nav-link,
.accordion-button {
  color: var(--fontColor) !important;
}
.home-link > .nav-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-link>.nav-link.active{
  color:var(--themeText) !important;
}
.home-link {
  width: 100% !important;
}
.right-block-title {
  font-weight: 600;
}
.nav-home {
  background-color: var(--primary) !important;
}

.attachment.attachment--preview > img,
.fig-img > img {
  width: 100%;
  height: auto;
}

.giphy-carousel {
  white-space: normal !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  max-height: 15rem !important;
}
.accordion-button {
  justify-content: start !important;
  background-color: var(--primary) !important;
  font-weight: 500;
  color: var(--fontColor) !important;
  /* padding-bottom: 0.5rem !important; */
}

.nav-body,
.nav-header > .accordion-button {
  background-color: var(--secondary) !important;
  flex-direction: row-reverse;
  padding:0.5rem 1rem;
}
.nav-icon{
  height:1.4rem;
}
.onboarding-header > .accordion-button {
  background-color: var(--secondary) !important;
}
.nav-header > .accordion-button::after {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  color: inherit;
}
.nav-header > .accordion-button:not(.collapsed)::after {
  transform: rotate(-90deg) !important;
  background-size: 1rem;
}
.nav-header > .accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}
.nav-scroll {
  scrollbar-width: thin;
}
.ce-inline-toolbar__dropdown,.ce-conversion-tool{
  color: black;
}
.nav-scroll::-webkit-scrollbar {
  width: 0.5rem;
}

.main-links {
  height: 3.125rem;
}

.grid-post {
  max-width: 30rem;
  align-self: start !important;
}
.react-datetime-picker__wrapper {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  padding: 1rem !important;
}
.react-datetime-picker__inputGroup__divider {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mobile-notification {
  margin-left: -1rem;
}
body,
.accordion-button,
.space-name,
button {
  font-size: 14px !important;
}
small {
  font-size: 12px !important;
}
.card.post-card > a {
  text-decoration: none;
}
.analytics-block {
  background: var(--secondary);
  border-radius: 0.25rem;
}
@media screen and (min-width: 2000px) {
  .nav-uuki {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }
  .home-left-nav {
    padding-left: 17rem;
  }
  .home-right-nav {
    padding-right: 17rem;
  }
  .fixed-nav {
    width: 25rem;
  }
}
.center-container {
  max-width: 40rem;
  overflow-x: hidden !important;
  color: var(--fontColor);
}

.ce-toolbox{
  color:black !important;
}
.loading-image {
  animation: zoom-out 2s linear;
}
@keyframes zoom-out {
  0% {
    transform: scale(0.7, 0.7);
  }
  100% {
    transform: scale(1, 1);
  }
}
.search-wrapper {
  min-width: 40rem;
  margin-left: -11.5rem;
  background-color: var(--primary);
  max-height: 30rem;
  overflow-y: scroll;
  box-shadow: 0 3px 3px 0 rgba(40, 42, 45, 0.2);
}
.search-result {
  height: 3.5rem;
}

.onboard-check > .form-check-input {
  border-radius: 50% !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.view-all-button{
  cursor: pointer;
  width: 98%;
  border-radius: 10%;;
  background-color: white;
}
.onboard-check > .form-check-input:disabled {
  opacity: 100% !important;
}
.onboard-check > .form-check-input:checked {
  background-color: #00ff47 !important;
  border-color: #00ff47 !important;
}
.search-section {
  width: 40rem !important;
  margin-left: 8rem !important;
}
.step-button{
  border-radius: 50%;
  padding: 0.2rem 0.65rem;
  color: white !important;
}
.step-incomplete{
  background-color: #A8A8A8;
  opacity:0.8;
}
.step-complete{
  background-color: #00FF47;
}
.active>.step-button{
  background-color: #577FF1;
}
.step-divider{
  width:2rem;
  height:0.1rem;
}
@media screen and (max-width: 2000px) and (min-width: 1600px) {
  .grid-post {
    max-width: 19.3rem;
  }
  .nav-uuki {
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }
  .home-left-nav {
    padding-left: 17rem;
  }
  .home-right-nav {
    padding-right: 17rem;
  }
  .fixed-nav {
    width: 20rem;
  }

}
@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .grid-post {
    max-width: 19.3rem;
  }
  .nav-uuki {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .home-left-nav {
    padding-left: 2rem;
  }
  .home-right-nav {
    padding-right: 2rem;
  }
  .fixed-nav {
    width: 17.5rem !important;
  }

}

@media screen and (max-width: 1400px) {
  .grid-post {
    max-width: 19rem;
  }

  .nav-uuki {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .fixed-nav {
    width: 17.5rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .grid-post {
    max-width: 15rem;
  }
  .search-section {
    width: 32rem !important;
  }
  .center-container {
    max-width: 30rem;
  }
  .fixed-nav {
    width: 14.5rem !important;
  }

}
@media screen and (min-width:500px) and (max-width: 900px) {
  .center-container {
    max-width: 90%;
  }
}
@media screen and (max-width: 800px) {
  .main-row {
    padding: 0 !important;
    margin: auto;
  }
  .grid-post {
    max-width: 100%;
  }
  .leaderboard-rank {
    border-radius: 0.25rem;
    font-size: 10px;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  }
  .attachment.attachment--preview > img,
  .fig-img > img {
    /* max-width: 100%; */
    height: auto;
  }
  .option-text{
    max-width: 15rem;
  }
  
}

.view-all-text {
  color: #21cd9c !important;
}

.responsive-embed {
  position: relative !important;
  overflow: hidden !important;
  width: 100% !important;
  padding-top: 56.25% !important; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-embed-youtube {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.see-more {
  backdrop-filter: blur(12px);
  background-color: transparent !important;
  border: 0px !important;
  color: var(--fontColor) !important;
}

div[id^="item-"] {
  max-height: 25rem;
}

.post-content iframe {
  width: 100%;
  min-width: 50px !important;
}

.powered-by{
  background-color: var(--accent);
  margin-bottom: 2px;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px 5px 0px 0px ;
  width: 140px;
  box-shadow: 0 0 0 3px #eff2f7;
  z-index: 9999;
  position: fixed;  
  bottom: 0;
  right: 0;
}

.powered-by a{
  color: var(--themeText);
  font-size: 14px;
}

.powered-by a:hover{
  color:var(--themeText);
  font-size: 14px;
  text-decoration: underline !important;
}

.likes-images{
  height:35px !important;
  width: 35px !important;

}