.members-search-container-bg {
  background-color: var(--primary) !important;
  
}


.member-profile-image {
  width: 3rem !important;
  height: 3rem !important;
}


.members-search-input {
  background: transparent !important;
  border: 0px !important;
  font-size: 14px !important;
}

.members-search-input:focus {
  background: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
}

.member-filter-dropdown {
  width: 200px !important;
  font-size: 14px !important;
}

.member-detail-card-bg {
  background-color: var(--primary);
}

.members-profile-pic {
  width: 52px;
  height: 52px;
  margin: 30px 16px 0 25px !important;
}





.member-roles {
  margin-top: 22px !important;
}

.member-admin-karma-text {
  border-radius: 8px;
  border: solid 1px #21cd9c;

  padding: 4px 15px 4px 15px;
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #21cd9c;
}
.members-hr {
  background-color: #d6dbe6 !important;
  height: 2px !important;
}

.members-link > .active {
  background-color: var(--accent) !important ;
  color: var(--themeText) !important ;
}

.members-link {
  color: var(--fontColor) !important ;  
}
.follow-btn{
  background-color: transparent !important;
  color: var(--fontColor) !important;
  padding: 0.1rem 0.4rem !important;
  font-size: 0.7rem !important;
  border-color: 2px solid var(--accent) !important;
  cursor: pointer;
}