html,
body {
  scroll-behavior: smooth;
  /* font-family: 'MetropolisMedium'; */
  font-size: 1rem;
  background-color: var(--secondary) !important;
  color: var(--fontColor) !important;
}
.signin-bg{
  background-color: var(--secondary);
}
.brand {
  background-color: var(--secondary);
}
/* .text-decoration-none {
	color: var(--accent) !important;
} */
.container > h1 {
  font-weight: 700;
  font-size: 1.75rem;
}
.form-label {
  font-weight: 500;
}
/* span {
	font-size: 0.9rem;
} */
.brand {
  height: 5rem;
  display: flex;
  align-items: center;
}
.signin-box {
  width: 100%;
}
@media screen and (min-width: 960px) {
  .brand {
    height: 100vh;
    align-items: flex-start;
  }
  .brand > span {
    font-weight: 700;
    font-size: 2rem;
  }
  .signin-box {
    width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .form-container {
    width: 100% !important;
    background: var(--primary);
    height: 100vh !important;
    overflow: auto;
  }
}
.brand-image-mobile {
  max-width: 15rem !important;
  max-height: 7rem;
}

.form-container {
  width: 50%;
  background: var(--primary);
  height: 100vh !important;
  overflow: auto;
  scrollbar-width: 10px !important;
}
