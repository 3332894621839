@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

.dashboard-wrapper {
  background-color: #eff1f5 !important;
  font-family: "Montserrat", sans-serif !important;
  color: #2d395a !important;
}
.dashboard-content {
  background-color: #ffffff;
}
.dashboard-logo{
  height:2.5rem;
}
.uuki-dashboard-btn {
  background-color: #21cd9c !important;
  color: #ffffff !important;
}
.uuki-big-btn {
  font-weight: 600;
  padding: 1rem 2rem 1rem 2rem !important;
  background-color: #21cd9c !important;
  font-size: 1.125rem !important;
  border: 0 !important;
  color: white !important;
}
.uuki-big-outline-btn {
  font-weight: 600 !important;
  padding: 1rem 2rem 1rem 2rem !important;
  background-color: transparent !important;
  border: 1px solid #21cd9c !important;
  font-size: 1.125rem !important;
  color: #21cd9c !important;
}

.uuki-delete-btn {
  font-weight: 600;
  padding: 0.8rem 2rem !important;
  background-color: #ff4c4c !important;
  font-size: 1.125rem ;
  border: 0 !important;
  color: #ffffff !important;
  min-width: 14rem;
}
.uuki-square-btn {
  background-color: #21cd9c;
  color: #ffffff;
  padding: 0.8rem 2rem !important;
}
.add-code-left-btn {
  border: 1px solid #21cd9c !important;
  font-weight: 600;
  font-size: 1.125rem !important;
  background-color: transparent !important;
  color: #21cd9c !important;
  border-right: none !important;
}
.add-code-right-btn {
  border: 1px solid #21cd9c !important;
  font-weight: 600;
  font-size: 1.125rem !important;
  background-color: transparent !important;
  color: #21cd9c !important;
  border-left: none !important;
}
.add-code-box {
  border: 1px solid #21cd9c !important;
  font-weight: 800 !important;
  font-size: 1.125rem !important;
  background-color: transparent !important;
  border-left: none !important;
  border-right: none !important;
  max-width: 6rem;
}

.nav-uuki {
  box-shadow: 0 1px 0 0 rgba(40, 42, 45, 0.12);
  background-color: #fff !important;
  height: 5.6rem;
}
.tabs-border-bottom {
  box-shadow: 0px -1px 0px 0px #21cd9c inset;
}
.center-container-dashboard {
  max-width: 50rem;
  overflow-x: hidden !important;
}
.nav-link {
  color: #2d395a;
  cursor: pointer;
}
.dashboard-tab {
  padding: 1rem 1.25rem 1rem 1.25rem !important;
}
.included-icon{
  min-height: 2rem !important;
  min-width: 2rem !important;
}
.community-button {
  padding: 2rem 3rem 2rem 3rem !important;
  background-color: #eff1f5 !important;
  border: 0 !important;
  color: inherit !important;
  width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.dashboard-link > .active {
  background-color: #21cd9c !important;
  color: #fff !important ;
}

.list-group-item {
  color: #2d395a !important;
}

.community-name {
  color: #21cd9c;
}
.notification-badge.bg-primary {
  background-color: #ff6060 !important;
}

@media screen and (max-width: 800px) {
  .community-button {
    width: 100%;
  }
}
