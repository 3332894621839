.tutor-bg{
    background-color: white;
}

.tutor-chat-container{
    height: calc(100vh - 152px);
     overflow-y: scroll; 
    
     padding-left: 50px;
     padding-right: 50px;
}

.tutor-msg{
    border-radius: 10px;
    max-width: 80%;
    word-wrap: break-word;
    animation: botmessage 0.2s ease-out 0s forwards;
}

.user-msg {
    background-color: #f2f2f2;
    color: black;
    align-self: flex-end;
    }
    
.bot-msg {
    background-color: #f2f2f2;
    color: black;
    align-self: flex-start;
    }

.bot-msg > pre{
    overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
}

@keyframes botmessage {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    50% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
  }

.tutor-powered-by{
    background-color: #637ee4;
    border-radius: 12px 12px 0 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    color: white !important;
}

.tutor-powered-by > a{
color: white !important;
}

.tutor-powered-by > a:hover{
    color: white !important;
    text-decoration: underline !important;
}

.icon{
    width: 1.3rem ;
    height: 1.3rem ;
    cursor: pointer ;
}

.discord-invite{
    background-color: #5865f2;
    color: white;
    border-radius: 12px;
    text-decoration: none;
    transition: box-shadow 0.3s ease-in-out;
  }

.discord-invite:hover{
    color: white;
    background-color: #4b59f7;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5) !important;
   
}

.tutor-producthunt-banner{
    z-index: 99;
    position: fixed;  
    top: 5rem;
    right: 2rem;
  }

  .tutor-input{
    width: 70% !important;
    border:1px solid #cfcfcf !important;
    border-radius: 8px !important;
  }

  /* .tutor-input-container{
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (min-width: 1000px ){
    .tutor-input-container{
        width: 80% !important;
    }
  } */