.leaderboard-karma-block {
	background: var(--secondary);
	/* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25); */
	border-radius: 8px;
}

.leaderboard-top-contributor-block {
	background: var(--secondary);
	box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	width: 12.5rem !important;
	/* min-width: 8rem !important; */
}

.leaderboard-top-contributor-image {
	border: solid 0.3rem gold;
	width: 3rem !important;
	height: 3rem !important;
}

.leaderboard-list-header {
	background: #21cd9c;
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
}

.view-leaderboard-btn {
	background: transparent !important;
	border: 0px !important;
	text-decoration: underline !important;
	color: cornflowerblue !important;
	text-align: start !important;
}
.leaderboard-karma-block > span {
	max-width: 6rem;
}
.leaderboard-column {
	width: 5rem;
}
