.comment-reaction-button {
	background-color: white !important;
	color:#2d395a !important;
}
.gif-button{
	color:#2d395a !important;
}
.comment-reaction-button:hover,
.comment-reaction-button:focus,
.comment-reaction-button:active {
	color: black !important;
}
trix-toolbar {
	display: none;
}
trix-editor.comment-input {
	border: 1px solid #ced4da;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
.comment-input{
	color: var(--fontColor) !important;
}
trix-editor:focus {
	outline: none !important;
	color: var(--fontColor) !important;
}
figcaption {
	display: none;
}
.emoji-btn {
	border-right: none !important;
    border-left: none !important;
    padding: 0px 0.25rem 0px 0px !important;
    border-radius: 0px;	
}
.gif-btn {
    border-left: none !important;
    max-width: 2rem !important;
    padding: 0px 0px 0px 0px !important;
    border-radius: 0px;
}
.comment-body-container {
	background: var(--secondary)!important;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	color: var(--fontColor) !important;
}

.comment-reply-container {
	background: var(--secondary)!important;
	border-radius: 4px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	margin-left: 8rem !important;
	color: var(--fontColor) !important;
}

.comment-author-image {
	width: 2.5rem !important;
	height: 2.5rem !important;
	/* margin-left: 0.5rem !important;
  margin-top: 0.4rem !important; */
}

.comment-author-text {
	font-family: MetropolisSemiBold !important;
	/* font-size: 1rem !important; */
	/* color: #2d395a !important; */
}

.comment-timestamp-text {
	opacity: 0.7;
	font-family: MetropolisMedium !important;
	font-size: 0.75rem !important;
	/* color: #2d395a !important; */
}

.comment-content {
	/* margin: 15px 70px 10px 75px !important; */
	font-family: MetropolisMedium !important;
	/* font-size: 1rem; */
	line-height: 1.39;
	/* color: #2d395a !important; */
}

.comment-footer {
	/* background-color: white !important; */
	width: 100% !important;
	padding-left: 4rem !important;
}

.comment-like-share-text {
	font-family: MetropolisMedium !important;
	font-size: 0.75rem;
	/* color: #2d395a; */
}

.gif-icon{
	width: 100%;
}

.btn-comment {
	background-color: #4f70f0 !important;
}

.comment-input {
	overflow: hidden;
	text-overflow: ellipsis !important;
}


@media screen and (max-width: 760px) {
	.comment-reply-container {
		margin-left: 2rem !important;
	}
}
