.lesson-video{
    position: relative;
    padding-bottom: 56.25%;
}
.lesson-video>iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.section-header>.accordion-button{
    background: #F1F1F1 !important;

}
.lesson-list{
    max-height: 90vh;
}
.question-edit>div:nth-child(2)>.codex-editor>.codex-editor__redactor{
    padding: 0 !important;

  }
#correct-true-selected-true{
    background-color: 	rgba(152, 251, 152, 0.5);
}
#correct-false-selected-true{
    background-color: 	rgba(255, 204, 203, 0.5);
}
@media screen and (max-width: 1200px){
    .admin-scroll{
        overflow-x: scroll;
    }
}