.chat-message {
  background-color: var(--secondary);
  border-radius: 0.25rem;
}
.message-area {
  overflow-y: scroll;
  scrollbar-width: thin;
}
.chat-profile-image {
  height: 2rem;
  width: 2rem;
}
.back-btn > i {
  color: var(--fontColor);
}
.message-area::-webkit-scrollbar {
  width: 0.5rem;
}
.chat-info-text {
  font-size: 10px;
  opacity: 0.8;
}
.chat-list {
  background-color: var(--primary);
  color: var(--fontColor);
  overflow-y: scroll;
}
.chat-content {
  background-color: var(--primary);
  color: var(--fontColor);
  height: 95vh;
}
/* trix-editor.chat-input {
  border: 1px solid #ced4da;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  width: 28rem;
} */
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #21cd9c ; 
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    opacity:0.5;
  }
  28% {
    transform: translateY(-7px);
    opacity:0.5; 
  }
  44% {
    transform: translateY(0px);
    opacity:0.5; 
  }
}
.reply-popup{
  background-color: var(--secondary);
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}
.reply-div>div{
  max-width: 5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (min-width: 768px ){
  .chat-content {
    height: 85vh;
  }
}
