.settings-wrapper {
  background-color: var(--secondary);
}
.settings-content {
  background-color: var(--primary);
  color: var(--fontColor) !important;
}

.input-group > button {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: inherit;
}
.settings-input-group{
  max-width: 30rem !important;
}
.form-floating>label{
  color: #2d395a !important;
}
.settings-input-group>button>i{
  color: #2d395a !important;

}
input[type="color"] {
  height: 3rem;
  width: 6rem;
  padding: 0;
  border: 1px solid black;
  cursor: pointer;
}

.form-check-input:not([type="checkbox"]) {
  cursor: pointer;
}

.space-group-field {
  min-width: 30rem;
}
.space-field {
  min-width: 26rem;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media screen and (max-width: 1280px) {
  .space-group-field {
    min-width: 25rem;
  }
  .space-field {
    min-width: 21rem;
  }
}

@media screen and (max-width: 768px) {
  .space-group-field {
    min-width: 20rem;
  }
  .space-field {
    min-width: 16rem;
  }
}
